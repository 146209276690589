


























































import { defineComponent, PropType } from "@vue/composition-api";

export default defineComponent({
  name: "IconApprove",
  props: {
    width: {
      type: Number as PropType<number>,
      default: () => 16,
    },
    height: {
      type: Number as PropType<number>,
      default: () => 12,
    },
    fill: {
      type: String as PropType<string>,
      default: () => "#fff",
    },
  },
});
