export const COLORS = [
  "#9954F2",
  "#6D3AA6",
  "#DBCCFA",
  "#E14761",
  "#AB213A",
  "#F7CFD5",
  "#3D75E4",
  "#214EB0",
  "#81ABEE",
  "#E9A35D",
  "#9E4F00",
  "#F8D6B5",
  "#57A003",
  "#467510",
  "#8DC95E",
  "#6F7A90",
  "#495469",
  "conic-gradient(from 180deg at 50% 50%, #FF0000 0deg, #FFA800 52.5deg, #FAFF00 103.13deg, #32F12E 163.12deg, #00FFFF 226.87deg, #1400FF 281.25deg, #D900FD 360deg)",
];
