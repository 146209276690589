































import { computed, defineComponent, PropType } from "@vue/composition-api";
import { Chrome } from "vue-color";

import ColorPickerPanel from "@/app/components/ColorPicker/ColorPickerPanel.vue";
import { COLORS } from "@/app/components/ColorPicker/constants";


export default defineComponent({
  name: "ColorPicker",
  components: {
    "color-picker": Chrome,
    ColorPickerPanel,
  },
  props: {
    value: {
      type: String as PropType<string>,
      default: () => "#000",
    },
    forbiddenColors: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
  },
  setup(props) {
    const selectedColorIndex = computed(() => {
      const foundIndex = COLORS.findIndex((el) => el.toLowerCase() === props.value.toLowerCase());
      return foundIndex > -1 ? foundIndex : COLORS.length - 1;
    });

    return {
      COLORS,
      selectedColorIndex, 
    };
  },
});
