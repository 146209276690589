































































import { computed, defineComponent, PropType, ref } from "@vue/composition-api";
import { VueEditor } from "vue2-editor";

import DateRangePicker from "@/app/components/Base/DateRangePicker.vue";
import DraggableStatusesList from "@/app/components/Base/DraggableStatusesList.vue";
import Dropzone from "@/app/components/Base/Dropzone.vue";
import VSelect from "@/app/components/Base/VSelect.vue";
import UserSelect from "@/app/components/User/UserSelect.vue";
import { PROJECT_DATA } from "@/shared/constants/components/Project/ProjectForm";
import { useRoute } from "@/shared/hooks";
import { useInitialTaskStatuses, usePriorities, useUsers } from "@/shared/hooks/fetchers";
import { useFindUsers } from "@/shared/hooks/users/useFindUsers";
import { ComboItem } from "@/shared/types/components/Base/VSelectItem";
import { Users } from "@/shared/types/components/Task/TaskForm";
import {
  InitialTaskStatus,
  ProjectInput,
} from "@/shared/types/schema.types";

type ComboKey = Extract<keyof ProjectInput, "executorId" | "coexecutorId" | "priorityCode">;

export default defineComponent({
  name: "ProjectForm",
  components: {
    VueEditor,
    DraggableStatusesList,
    DateRangePicker,
    VSelect,
    UserSelect,
    Dropzone,
  },
  props: {
    value: {
      type: Object as PropType<ProjectInput>,
      default: () => null,
    },
    initialUsers: {
      type: Array as PropType<Users>,
      default: () => [],
    },
  },

  setup(props, { emit }) {
    const route = useRoute();
    const projectId = route.params.id;
    const { findUser, usersParams } = useFindUsers();
    const { data: users } = useUsers(usersParams.value);
    const { data: projectPriorities } = usePriorities();
    const { data: initialTaskStatuses, onResult } = useInitialTaskStatuses();

    const projectData = ref(
      _.merge(
        _.cloneDeep(PROJECT_DATA),
        _.cloneDeep(props.value),
        !projectId
          ? { taskStatusInputs: initialTaskStatuses.value }
          : {},
      ),
    );

    onResult((res) => {
      if (projectId) return;
      projectData.value.taskStatusInputs = res.data.initialTaskStatuses as InitialTaskStatus[];
    });


    const usersOptions = computed<Users>(() => {
      return _.uniqBy([...props.initialUsers, ...users.value || []], 'id');
    });

    function onDataPickerChange(date: string) {
      projectData.value.deadline = date;
      emit('input', projectData.value);
    }

    const changeSelect = (k: ComboKey, ev: ComboItem | null) => {
      if (!projectData?.value || !k) return;
      projectData.value = {
        ...projectData.value,
        [k]: (ev && ev.toString()) || null,
      };
      onValueChange();
    };

    function onValueChange() {
      emit('input', projectData.value);
    }

    return {
      projectData,
      usersOptions,
      projectPriorities,
      onDataPickerChange,
      findUser,
      changeSelect,
      initialTaskStatuses,
      onValueChange, 
    };
  },
});
