import { encodeAttachmentInput } from "@/models/AttachmentInput";
import {
  ProjectInputExt,
  SYSTEM_STATUS_CATEGORY_CODES,
} from "@/shared/types/components/Project/Project";
import { GetProjectQuery, ProjectInput, TaskStatusInput } from "@/shared/types/schema.types";
import { formatDateTimeForDatepicker } from "@/shared/utils";

interface ProjectOutput {
  projectId?: string;
  projectInput: ProjectInput;
}

export function decodeProject(projectData: GetProjectQuery): ProjectInput {
  const project = projectData.project;
  return {
    ..._.pick(project, ["name", "description"]),
    coexecutorId: project.coexecutor?.id,
    executorId: project.executor?.id || "",
    attachmentInputs: project.descriptionAttachments,
    priorityCode: project.priority.code,
    deadline: formatDateTimeForDatepicker(project.deadline),
    taskStatusInputs: project.taskStatuses as Array<TaskStatusInput>,
  };
}

export function encodeProject(project: ProjectInputExt, projectId?: string): ProjectOutput {
  const projDto = {
    projectInput: {
      ..._.pick(project, [
        "description",
        "name",
        "executorId",
        "coexecutorId",
        "priorityCode",
        "deadline",
      ]),
      attachmentInputs: encodeAttachmentInput(project.attachmentInputs),
      taskStatusInputs: project.taskStatusInputs.map((item) => {
        if (!item) return {};
        return {
          statusCategoryCode: projectId ? null : item.statusCategoryCode,
          statusId: item.statusId ? `${item.statusId}` : null,
          colorValue: item.colorValue,
          name:
            item.statusCategoryCode &&
            SYSTEM_STATUS_CATEGORY_CODES.includes(item.statusCategoryCode)
              ? null
              : item.name,
        };
      }),
    },
  };
  return projectId
    ? {
        ...projDto,
        projectId: projectId,
      }
    : projDto;
}
