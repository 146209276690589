






























































import { computed, defineComponent, PropType, ref, watch } from "@vue/composition-api";
import draggable from "vuedraggable";

import ColorPicker from "@/app/components/ColorPicker/ColorPicker.vue";
import { COLORS } from "@/app/components/ColorPicker/constants";
import { ExtendedTaskStatus } from "@/shared/types/components/Project/Project";
import { StatusCategoryCode } from "@/shared/types/schema.types";

export default defineComponent({
  name: "DraggableStatusesList",
  components: {
    draggable,
    ColorPicker,
  },
  props: {
    value: {
      type: Array as PropType<ExtendedTaskStatus[]>,
      default: () => null,
    },
  },
  setup(props, context) {
    const sortedItems = ref(_.cloneDeep(props.value));
    const newStatusLabel = ref("");

    const AVAILABLE_COLORS = COLORS.slice(0, -1);

    const forbiddenColors = computed(() => {
      if (!sortedItems.value) return [];
      return sortedItems.value.map((item) => item.colorValue);
    });

    const findAvailableColor = (colors: string[] = AVAILABLE_COLORS, tries = 3): string => {
      if (!tries) return "";
      const filteredColors = colors.filter((color, i) => i % tries === 0);
      const foundColor = filteredColors.find((el) => !forbiddenColors.value.includes(el)) || "";
      return (
        foundColor || findAvailableColor(_.difference(AVAILABLE_COLORS, filteredColors), --tries)
      );
    };

    const addItem = (): void => {
      context.emit("input", [
        ...sortedItems.value,
        {
          name: newStatusLabel.value,
          statusCategoryCode: StatusCategoryCode.UserDefined,
          colorValue: findAvailableColor() || "#000000",
          temporaryId: _.uniqueId("temp-"),
        },
      ]);
      newStatusLabel.value = "";
    };

    const removeItem = (temporaryId: string): void => {
      context.emit(
        "input",
        sortedItems.value.filter((item) => item.temporaryId !== temporaryId),
      );
    };


    watch(
      () => props.value,
      (val) => {
        if (!val) return;
        sortedItems.value = val;
      },
    );

    const endSorting = () => context.emit("input", sortedItems.value);

    return {
      sortedItems,
      newStatusLabel,
      addItem,
      removeItem,
      endSorting,
      forbiddenColors, 
    };
  },
});
