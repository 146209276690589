



































import { computed, defineComponent, PropType } from "@vue/composition-api";

import { COLORS } from "@/app/components/ColorPicker/constants";
import IconApprove from "@/app/components/Icons/IconApprove.vue";

export default defineComponent({
  name: "ColorPickerPanel",
  components: {
    IconApprove,
  },
  props: {
    value: {
      type: String,
      default: () => "#000",
    },
    forbiddenColors: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
  },
  setup(props) {
    const selectedColorIndex = computed(() => {
      const foundIndex = COLORS.findIndex((el) => el.toLowerCase() === props.value.toLowerCase());
      return foundIndex > -1 ? foundIndex : COLORS.length - 1;
    });

    return {
      COLORS,
      selectedColorIndex, 
    };
  },
});
