import { ProjectInputExt, ProjectResultInputExt } from "@/shared/types/components/Project/Project";
import { ProjectPriorityCode } from "@/shared/types/schema.types";

export const PROJECT_DATA: ProjectInputExt = {
  name: "",
  priorityCode: ProjectPriorityCode.Normal,
  executorId: "",
  coexecutorId: "",
  deadline: "",
  description: "<div></div>",
  attachmentInputs: [],
  taskStatusInputs: [],
};

export const PROJECT_RESULT_DATA: ProjectResultInputExt = {
  body: "",
  attachmentInputs: [],
};
