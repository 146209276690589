import { AttachmentItemData } from "@/shared/types/components/Base/AttachmentItem";
import {
  InitialTaskStatus,
  ProjectFilter,
  ProjectInput,
  ProjectResultInput,
  ProjectsQuery,
  StatusCategoryCode,
} from "@/shared/types/schema.types";

export type ProjectType = {
  name: string;
  code: string;
};

export type ProjectStatus = ProjectType;
export type ProjectPriority = ProjectType;

export type BaseProjectInfo = ProjectsQuery["projectSheet"]["projects"][0];

export const SYSTEM_STATUS_CATEGORY_CODES = [
  StatusCategoryCode.Todo,
  StatusCategoryCode.InProgress,
  StatusCategoryCode.Done,
];

export interface ExtendedTaskStatus extends InitialTaskStatus {
  temporaryId?: string;
}

export interface ModifiedProjectFilter extends Omit<ProjectFilter, "deadlineInterval"> {
  deadlineInterval: {
    begin?: number;
    end?: number;
  };
}

export type ProjectInputExt = Omit<ProjectInput, "attachmentInputs"> & {
  attachmentInputs: AttachmentItemData[];
};

export type ProjectResultInputExt = Omit<ProjectResultInput, "attachmentInputs"> & {
  attachmentInputs: AttachmentItemData[];
};
